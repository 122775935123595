import React, { useState, useLayoutEffect, Component, FunctionComponent, useEffect } from 'react';
import Service from './Service';

const serviceData = [
  {
    name: "Home Automation",
    image: "/images/home-automation.png",
    content: `
        <p>
          Home automation is the ability to control multiple items
          around your home with a touch of a button. Entertainment,
          appliances and even home security can be streamlined into
          one simple system.
        </p>
        <p>
          iSync offers Control4 home system, which coordinators the
          technology in your house into simple interactions that fit
          your lifestyle and is easy to enjoy.From universal remotes
          and hi-res audio to mobile security features this system
          gives their users the full smart home experience.
        </p>
      `,
    brands: [{
      name: "Control 4",
      image: "/images/logos/control-4.png",
      link: "https://www.control4.com/"
    }]
  },
  {
    name: "Voice Control",
    image: "/images/voice-control.png",
    content: `
          <p>
            The sound of your voice can be a powerful thing when you allow iSync
            to build your dream smart home. With Amazon Alexa you can lock your doors,
            start your vacuum or turn down the thermostat with a simple command.
            Let Alexa be your personal home assistant.
          </p>
        `,
    brands: [{
      name: "Amazon Alexa",
      image: "/images/logos/amazon-alexa.png",
      link: "https://www.amazon.com/b/ref=aeg_d_nav_cat?node=17934671011"
    }]
  },
  {
    name: "Distributed Audio",
    image: "/images/distributed-audio.png",
    content: `
          <p>
            Sonos is the wireless Home Sound System that can turn your home into a
            concert hall or a movie theater. Movies and music can be streamed via WiFi
            and the speakers can easily be controlled with an app. iSync can amp up
            your home today.
          </p>
        `,
    brands: [{
      name: "Sonos",
      image: "/images/logos/sonos.png",
      link: "https://www.sonos.com/en-us/home"
    }]
  },
  {
    name: "Networking and Wifi",
    image: "/images/networking-and-wifi.png",
    content: `
          <p>
            Technology has made Wi-Fi a necessity and no smart home is complete without it.
            Nothing is more frustrating than an overwhelmed network.  iSync can make sure your
            Wi-Fi network can handle your growing demand without any interruptions.
          </p>
        `,
    brands: [{
      name: "Cisco",
      image: "/images/logos/cisco.png",
      link: "https://www.cisco.com/c/en/us/products/index.html"
    }]
  },
  {
    name: "Lighting Controls",
    image: "/images/lighting-controls.png",
    content: `
          <p>
            The Control4 system gives you the ability to dim the lights without getting off the
            couch or quickly turn them on when it’s time for a midnight snack. Eliminate fumbling
            for the lightswitch by making your entire lighting system accessible at the touch of
            a button.
          </p>
        `,
    brands: [{
      name: "Control 4",
      image: "/images/logos/control-4.png",
      link: "https://www.control4.com/"
    }]
  },
  {
    name: "Window Treatements",
    image: "/images/window-treatments.png",
    content: `
          <p>
            Windows have been around for years, but iSync can take yours out of the dark ages. Window
            treatment systems like QMotion and Lutron incorporate smart shading to save money and lower
            energy consumption.
          </p>
        `,
    brands: [
      {
        name: "Q Motion",
        image: "/images/logos/qmotion.png",
        link: "http://www.qmotionshades.com/"
      },
      {
        name: "Lutron",
        image: "/images/logos/lutron.png",
        link: "http://www.lutron.com/en-US/Pages/default.aspx"
      }

    ]
  },
  {
    name: "Security And Surveillance",
    image: "/images/security-and-surveillance.png",
    content: `
          <p>
            Your home or business is important to you and making sure it’s protected 24/7 is something
            iSync can provided to its customers. Security systems like Alarm.com, Nest, and Ring gives
            you the ability to keep an eye on your home no matter matter the distance.
          </p>
        `,
    brands: [
      {
        name: "Nest",
        image: "/images/logos/nest.png",
        link: "https://nest.com/"
      },
      {
        name: "Ring",
        image: "/images/logos/ring.png",
        link: "https://ring.com/"
      },
      {
        name: "Alarm.com",
        image: "/images/logos/alarm.png",
        link: "https://www.alarm.com/"
      },
    ],
  }
];

const Services: FunctionComponent<{}> = () => {

  const [isTablet, setIsTable] = useState(false);

  const handleResize = () => {
    requestAnimationFrame(() => {
      setIsTable(window.matchMedia("(min-width: 768px)").matches);
    });
  };

  useLayoutEffect(() => {
    setIsTable(window.matchMedia("(min-width: 768px)").matches);
  });

  useLayoutEffect(() => {

    if (typeof window !== undefined) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  return (
    <section
      className="Services"
      style={{ minHeight: `${isTablet ? `${serviceData.length * 100}vh` : `auto`}` }}
    >
      {
        serviceData.map((data, index) => (
          <Service
            index={index}
            header={data.name}
            image={data.image}
            key={data.name}
            isLast={index === (serviceData.length - 1)}
            isTablet={isTablet}
            brands={data.brands}
          >
            <div dangerouslySetInnerHTML={{ __html: data.content }}>
            </div>
          </Service>
        ))
      }
    </section>
  )
}

export default Services;
