import React, { FunctionComponent, Fragment } from 'react';

interface IBrandsProps {
  logos: Array<{ name: string; image: string; link: string }>;
}

const Brands: FunctionComponent<IBrandsProps> = ({ logos }) => {

  return (
    <Fragment>
      <div className="Brands">
        {
          logos.map((logo) => {
            return <a href={logo.link} key={logo.name}><img src={logo.image} alt={logo.name} /></a>
          })
        }
      </div>

    </Fragment>
  )
}

export default Brands;
