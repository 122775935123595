import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import Brands from '../Brands/Brands';

const Service = ({ image, header, children, index, isLast, isTablet, brands }) => {

  let element = null;
  let rect;
  let yPos;
  let yPosEnd;

  const refCallback = el => {
    if (el) {
      element = el;
      rect = el.getBoundingClientRect();
      yPos = rect.top;
      yPosEnd = rect.bottom;
    }
  }

  const handleScroll = () => {
    if (element === null) {
      return;
    }

    requestAnimationFrame(() => {
      let scrollPos = window.scrollY + 65;

      if (isLast) {
        element.classList.remove('is-sticky');
        element.style.opacity = 1
        return;
      }

      if (isTablet) {
        /* The viewport is at least 400 pixels wide */

        if (scrollPos >= yPos && scrollPos <= yPosEnd) {

          let opacity = (yPos / scrollPos)

          element.style.opacity = opacity;

          if (!element.classList.contains('is-sticky')) {
            element.classList.add('is-sticky');

          }
        } else {
          if (element.classList.contains('is-sticky')) {
            element.classList.remove('is-sticky');
            element.style.opacity = 1
          }
        }
      }
    })
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => { window.removeEventListener('scroll', handleScroll) }
  }, [isLast, isTablet])

  return (
    <div className="Service"
      ref={refCallback}
      style={{ top: `${isTablet ? `${index * 100}vh` : 'auto'}` }}>
      <div className="Service-photo" >
        <img src={image} alt={`isync ${header}`} />
      </div>
      <div className="Service-content">
        <h2>{header}</h2>
        {children}
        <Brands logos={brands} />
      </div>
    </div>
  )
};

export default Service;
