import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import posed, { PoseGroup } from 'react-pose';
import Container from '../Container/Container';
import './Process.scss';

interface ProcessState {
  activeProcess: string;
}

interface ProcessProps {

}


const ListContainer = posed.div({
  enter: {
    x: 0,
    opacity: 1,
    delayChildren: 250,
    staggerChildren: 300,
    transition: {
      x: {
        type: 'spring',
        stiffness: 500,
        damping: 1000,
      },
      default: {
        duration: 250,
      },
    },
  },
  exit: {
    x: -150,
    opacity: 0,
    transition: {
      duration: 250
    }
  }
});

const List = posed.li({
  enter: {
    x: 0,
    opacity: 1,
    transition: {
      x: {
        type: 'spring',
        stiffness: 50,
        damping: 1000,
      },
      default: {
        duration: 300,
      },
    },
  },
  exit: {
    x: -50,
    opacity: 0,
    transition: { duration: 250 }
  }
});

class Process extends Component<ProcessProps, ProcessState> {
  constructor(props) {
    super(props);

    this.state = {
      activeProcess: 'consultation',
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(value: string): void {
    this.setState({
      activeProcess: value,
    })
  }

  render() {

    const { activeProcess } = this.state;

    return (
      <section className="Process">
        <Container>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={9}>
              <h2>How It works.</h2>
              <div className="Process-Trigger">
                <button onClick={() => { this.handleClick('consultation') }}>1. Consultation</button>
                <button onClick={() => { this.handleClick('design') }}>2. Design</button>
                <button onClick={() => { this.handleClick('implementation') }}>3. Implementation</button>
              </div>
            </Grid.Column>
          </Grid>

          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <div className="Process-description">
                <PoseGroup>
                  {
                    activeProcess == 'consultation' &&
                    <ListContainer
                      key="consultation"
                    >
                      <h2>1. Consultation</h2>
                      <ul className="Marketing">
                        <List>
                          Home automation can sound expensive, but at iSync Solutions we can help you create your dream customization for the right price.
                          </List>
                        <List>
                          We will figure out your budget upfront and work within it to give you the best experience in system integration.
                          </List>
                        <List>
                          At iSync you have complete control over your vision and we will be there every step of the way to make your dream a reality.
                          </List>
                      </ul>
                    </ListContainer>
                  }

                  {
                    activeProcess == 'design' &&
                    <ListContainer
                      key="design"
                    >
                      <h2>2. Design</h2>
                      <ul className="Marketing">
                        <List>
                          At iSync we know there is not a cookie cutter method to installation.
                          We will give you a custom proposal that is drawn for your space.
                          There are no surprises when it comes to installation as the equipment is handpicked by you.
                          Before building begins we will present you with a plan that you must agree with before we can continue.
                           You have a vision when it comes to your home or business installation and we are here to assist not change that plan.
                        </List>
                      </ul>
                    </ListContainer>
                  }

                  {
                    activeProcess == 'implementation' &&
                    <ListContainer
                      key="implementation"
                    >
                      <h2>3. Implementation</h2>
                      <ul className="Marketing">
                        <List>
                          Installation requires a prewire or “rough-in” phase that ensures your home is ready for any components you may want to add.
                          We will “prep” your entire home to make sure it can handle any future additions.
                          From wall paint to television mounts, iSync specializes in educating you about every
                          single step on the way to your completed vision.
                        </List>
                        <List>
                          All iSync installations will have a user friendly interface that doesn’t require a degree in rocket science to use.
                           Even after installation is done, iSync is there for you with maintenance, inspection, remote diagnostics,
                           and more because we strive to provide proactive support for your vision.
                      </List>
                      </ul>
                    </ListContainer>
                  }

                </PoseGroup>
              </div>
            </Grid.Column>
            <Grid.Column computer={8}>
              <div className="Process-visual">
                {
                  activeProcess == 'consultation' &&
                  <div style={{ backgroundImage: 'url(/images/process/consultation.jpg)' }} />
                }

                {
                  activeProcess == 'design' &&
                  <div style={{ backgroundImage: 'url(/images/process/design.jpg)' }} />
                }
                {
                  activeProcess == 'implementation' &&
                  <div style={{ backgroundImage: 'url(/images/process/implementation.jpg)' }} />
                }

              </div>
            </Grid.Column>
          </Grid>
        </Container>
      </section>
    )
  }
};

export default Process;
