import React from 'react';
import Container from '../Container/Container';
import { Grid } from 'semantic-ui-react';
import { Link } from 'gatsby';

const HelpSection = () => (
  <section className="HelpSection">
    <Container>

      <Grid stackable>
        <Grid.Column width={11}>
          <h2>How can we help?</h2>
          <p>
            A smart home can seem like a daunting experience, but at iSync we are here to make
          your dream a reality. Whether it is simple or complex <b> we will work within your
          budget to deliver the best in automation</b>. We want to make sure the technology
          fits your lifestyle and we will tailor the experience to fit you. <b> Let iSync guide
          you to your connected world.</b>
          </p>
        </Grid.Column>
        <Grid.Column width={4} className="HelpSection-cta" floated='right'>
          <Link to='/contact' >
            <button>
              <img src="/images/isync-help-cta.png" alt="" />
            </button>
          </Link>

          {/* <ContactForm trigger={open => (
            <button onClick={open}>
              <img src="/images/isync-help-cta.png" alt="" onClick={open} />
            </button>
          )} /> */}
        </Grid.Column>
      </Grid>

    </Container>
  </section>
)

export default HelpSection
