import React from 'react'
import Layout from '../components/layout'
import Instagram from '../components/Instagram/Instagram';
import Process from '../components/Process/Process';
import Services from '../components/Services/Services';
import Hero from '../components/Hero/Hero';
import ContactForm from '../components/ContactForm/ContactForm';
import { Link } from 'gatsby';
import { Button } from 'semantic-ui-react';
import HelpSection from '../components/HelpSection/HelpSection';

const IndexPage = () => (
  <Layout>
    <div>
      <Hero>
        <h1>
          Your World. <br />
          Our Passion.
        </h1>
        <p>
          We are more connected to our friends and family than ever before
          thanks to advancements in technology.
          <br />
          <br />
          Why should our home or business be any different?
        </p>
        <div>
          <Link to='/contact' ><Button className="green">Contact Us</Button></Link>
          {/* <ContactForm trigger={open => <Button onClick={open} className="green">Contact Us</Button>} /> */}
        </div>
      </Hero>
      <Process />
      <Services />
      <Instagram />
      <HelpSection />
    </div>
  </Layout>
)

export default IndexPage
