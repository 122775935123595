
import React, { FC, useState, useLayoutEffect, useEffect } from 'react';
import axios from 'axios';
import { Card, Responsive, Container } from 'semantic-ui-react';

const Instagram: FC<{}> = () => {

  const [posts, setPosts] = useState<Array<Object>>([]);
  const [isTabletScreen, setIsTabletScreen] = useState<boolean>(true);

  const isWindowTabletSized = () => {
    const tabletSized = window.matchMedia("(min-width: 768px)").matches;
    setIsTabletScreen(tabletSized);
  }

  useEffect(() => {
    axios
      .post('/.netlify/functions/get-instagram-timeline')
      .then(response => {
        setPosts(response.data.feed)
      });
  }, [])

  useLayoutEffect(() => {
    window.addEventListener('resize', isWindowTabletSized);

    return () => {
      window.removeEventListener('resize', isWindowTabletSized);
    }
  }, []);

  return (
    <section className="Instagram">
      <Container>
        {
          <Card.Group itemsPerRow={isTabletScreen ? 4 : 2}>
            {
              posts.map((post: any) => {
                return (
                  <Card href={post.link} key={post.id} image={post.images.standard_resolution.url} />
                )
              })
            }
          </Card.Group>
        }
      </Container>
    </section>
  )
};

export default Instagram;
